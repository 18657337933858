import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2560.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)" >

<path d="M12715 16400 c-72 -9 -95 -18 -95 -36 0 -18 25 -30 85 -42 114 -24
301 -88 385 -133 19 -11 38 -19 43 -19 11 0 193 -110 215 -130 9 -9 32 -26 50
-39 108 -76 271 -248 374 -396 35 -50 94 -154 155 -275 19 -36 45 -83 58 -105
13 -22 31 -56 40 -75 21 -48 33 -72 43 -90 5 -8 41 -81 81 -162 40 -81 76
-150 82 -153 5 -4 9 -14 9 -23 1 -9 9 -33 20 -52 10 -19 30 -57 44 -85 14 -27
30 -57 35 -65 4 -8 48 -96 96 -195 48 -99 104 -214 124 -255 21 -41 57 -113
80 -160 23 -47 61 -121 83 -165 23 -44 46 -91 52 -104 6 -14 26 -53 44 -88 17
-35 32 -70 32 -77 0 -8 6 -21 13 -28 12 -13 54 -91 148 -278 99 -198 94 -179
97 -345 3 -118 0 -160 -12 -193 -9 -23 -23 -60 -31 -82 -16 -43 -68 -120 -116
-171 -25 -27 -32 -30 -56 -22 l-28 10 -6 129 c-7 133 -18 195 -44 228 -8 11
-15 26 -15 34 0 8 -13 40 -28 71 -16 31 -34 73 -41 94 -7 20 -16 37 -21 37 -5
0 -14 17 -21 38 -6 20 -15 42 -19 47 -10 12 -133 256 -154 305 -9 19 -20 42
-24 50 -5 8 -27 51 -49 95 -22 44 -48 94 -56 110 -9 17 -48 95 -87 175 -39 80
-75 152 -80 160 -4 8 -17 33 -27 55 -10 22 -35 72 -55 110 -20 39 -54 108 -77
155 -23 47 -49 101 -60 120 -10 19 -38 73 -61 120 -23 47 -108 216 -189 376
-80 160 -151 302 -157 315 -103 216 -305 460 -458 553 -17 10 -33 22 -36 26
-5 7 -27 20 -154 88 -33 18 -85 40 -115 48 -30 8 -60 18 -65 23 -13 10 -69 23
-228 52 -125 22 -259 23 -368 1 -30 -6 -86 -16 -125 -23 -38 -6 -81 -17 -95
-25 -14 -7 -49 -20 -79 -30 -30 -9 -68 -25 -85 -34 -17 -10 -42 -21 -55 -25
-33 -10 -139 -79 -194 -128 -17 -15 -38 -29 -47 -32 -18 -6 -162 -153 -206
-210 -57 -74 -138 -202 -154 -245 -6 -14 -15 -32 -20 -40 -6 -8 -19 -33 -29
-55 -28 -61 -225 -463 -299 -610 -36 -71 -70 -141 -76 -154 -22 -47 -146 -296
-155 -311 -8 -12 -216 -436 -288 -585 -11 -22 -24 -49 -30 -60 -7 -11 -32 -60
-56 -110 -25 -49 -52 -103 -60 -120 -112 -222 -158 -401 -164 -650 -8 -283 41
-491 170 -730 6 -11 18 -33 26 -50 26 -49 88 -141 118 -174 16 -17 49 -55 73
-85 25 -30 53 -58 62 -63 10 -4 39 -28 65 -53 54 -52 146 -120 160 -120 6 0
18 -9 27 -19 10 -11 32 -25 49 -32 18 -7 43 -21 56 -31 13 -10 30 -18 38 -18
8 0 28 -9 45 -21 16 -12 55 -28 86 -36 31 -8 68 -21 83 -28 14 -8 72 -21 130
-30 57 -9 128 -20 158 -25 30 -6 75 -10 100 -10 88 0 356 42 420 66 35 13 71
24 80 24 8 0 38 11 66 24 162 78 242 117 255 126 35 23 148 68 187 74 24 3 57
12 75 20 18 8 60 19 94 25 75 14 413 14 474 1 69 -16 240 -70 286 -91 22 -11
45 -19 51 -19 6 0 21 -8 34 -18 13 -10 40 -24 59 -32 19 -7 47 -22 62 -32 14
-10 33 -18 41 -18 8 0 29 -10 48 -23 107 -72 507 -149 663 -128 125 17 300 52
326 66 14 7 41 16 60 20 48 10 276 124 336 169 27 20 65 47 84 60 65 43 246
230 252 258 3 15 3 30 0 33 -14 14 -56 1 -110 -35 -33 -22 -65 -40 -72 -40 -6
0 -25 -9 -41 -20 -15 -12 -57 -30 -91 -41 -35 -12 -67 -24 -73 -29 -5 -4 -41
-15 -80 -24 -38 -9 -95 -23 -125 -31 -77 -21 -365 -21 -440 1 -30 9 -86 22
-125 29 -38 8 -119 32 -180 55 -60 23 -128 48 -150 56 -22 8 -107 48 -190 89
-175 86 -287 135 -310 135 -8 0 -33 9 -53 19 -21 10 -82 26 -137 35 -55 9
-122 20 -150 25 -68 13 -346 13 -420 0 -33 -5 -97 -16 -143 -24 -120 -20 -196
-48 -467 -166 -134 -58 -213 -70 -362 -54 -56 6 -107 16 -115 22 -7 5 -32 17
-56 27 -24 9 -67 34 -95 55 -99 75 -162 157 -212 276 -26 63 -29 84 -33 203
-4 127 -2 144 28 217 15 36 101 220 111 235 5 8 14 27 20 42 10 25 32 69 113
222 17 33 36 69 41 80 16 33 245 499 254 516 4 8 38 76 75 150 37 74 74 149
83 165 8 17 47 93 85 170 38 77 74 149 80 160 6 11 18 34 25 50 8 17 19 39 26
50 6 11 19 38 30 60 179 374 302 607 349 662 29 34 61 65 70 68 38 14 68 47
68 75 0 27 -4 30 -70 50 -112 33 -182 36 -285 10 -154 -39 -280 -137 -350
-272 -11 -22 -51 -101 -88 -176 -38 -75 -76 -150 -85 -167 -8 -16 -19 -37 -24
-45 -4 -8 -14 -31 -23 -50 -8 -19 -19 -42 -24 -50 -5 -8 -100 -197 -211 -420
-110 -223 -209 -421 -219 -440 -11 -19 -34 -66 -51 -105 -17 -38 -38 -81 -45
-95 -28 -49 -365 -727 -365 -734 0 -3 -9 -25 -20 -49 -42 -90 -72 -362 -51
-462 5 -27 15 -88 21 -134 10 -71 9 -86 -3 -99 -24 -23 -64 -7 -94 39 -72 107
-104 170 -148 289 -23 61 -48 275 -42 353 10 123 28 218 53 286 25 66 78 183
95 211 5 8 17 31 26 50 24 51 192 391 207 420 8 14 57 113 110 220 53 107 100
202 105 210 5 8 55 110 111 225 181 371 351 719 367 750 8 17 33 66 56 110 49
97 125 200 205 278 32 31 61 57 64 57 3 0 38 22 79 48 41 27 94 56 119 64 25
9 64 23 86 32 23 8 92 21 153 27 139 15 316 -1 401 -36 30 -12 65 -26 77 -29
51 -16 176 -101 252 -173 118 -110 134 -134 277 -423 53 -107 100 -202 104
-210 43 -78 200 -398 200 -407 0 -6 10 -24 23 -40 12 -15 55 -93 95 -173 40
-80 76 -152 81 -160 4 -8 22 -44 39 -80 27 -58 102 -210 123 -250 4 -8 26 -53
49 -100 23 -47 62 -123 86 -170 24 -47 48 -96 54 -110 5 -14 14 -32 20 -40 5
-8 16 -31 25 -50 8 -19 22 -46 30 -60 8 -14 22 -41 30 -60 8 -19 19 -42 25
-50 5 -8 30 -58 55 -110 25 -52 49 -102 55 -110 27 -43 70 -163 70 -197 0 -56
-39 -122 -95 -160 -40 -28 -56 -33 -104 -33 -31 0 -67 7 -81 15 -14 8 -32 17
-40 20 -8 4 -32 14 -52 22 -21 9 -38 20 -38 24 0 4 -10 11 -22 14 -13 4 -73
31 -135 61 -62 30 -120 54 -130 54 -10 0 -42 11 -71 25 -30 14 -65 25 -78 25
-13 0 -56 11 -95 25 -38 13 -104 29 -146 35 -42 5 -103 18 -137 27 -90 25
-648 24 -751 -1 -38 -9 -101 -21 -140 -27 -38 -6 -95 -19 -125 -30 -30 -12
-75 -25 -100 -29 -73 -14 -267 -89 -361 -140 -20 -11 -44 -20 -52 -20 -9 0
-28 -10 -44 -23 -15 -13 -43 -29 -60 -36 -49 -20 -77 -72 -77 -145 -1 -146
135 -262 281 -242 25 3 105 33 178 66 73 33 139 60 148 60 8 0 37 9 62 20 26
11 75 27 109 34 33 8 90 21 126 29 60 14 196 30 381 44 88 7 394 -21 484 -44
33 -8 87 -21 120 -28 118 -27 321 -107 450 -178 39 -21 88 -47 110 -57 22 -11
47 -24 55 -29 25 -17 157 -71 173 -71 8 0 30 -6 49 -14 95 -40 372 -60 490
-35 70 15 228 67 253 83 11 7 35 18 53 24 18 6 45 21 60 32 15 12 64 50 110
85 93 72 89 68 158 150 55 66 83 108 113 171 11 23 24 45 29 48 6 3 16 23 22
44 7 20 21 57 32 82 65 144 60 561 -8 690 -13 25 -24 52 -24 61 0 9 -8 27 -19
40 -10 13 -21 35 -25 49 -9 34 -181 388 -243 500 -8 14 -22 44 -33 66 -10 23
-23 45 -29 48 -6 4 -11 15 -11 24 0 9 -7 31 -16 47 -67 124 -104 198 -114 229
-6 20 -16 36 -21 36 -5 0 -9 5 -9 11 0 6 -30 70 -67 143 -36 72 -79 158 -95
191 -16 33 -33 67 -38 75 -5 8 -28 53 -50 100 -43 90 -89 182 -120 240 -10 19
-58 116 -105 215 -92 191 -220 414 -267 465 -15 17 -28 35 -28 39 0 12 -176
189 -255 258 -100 86 -213 159 -345 224 -177 87 -204 97 -410 150 -35 9 -357
40 -395 37 -11 0 -47 -4 -80 -8z"/>
<path d="M18223 10544 c-160 -17 -242 -66 -294 -175 -49 -101 -54 -142 -53
-439 1 -258 2 -279 23 -340 25 -74 73 -129 163 -191 31 -21 59 -39 61 -39 8 0
219 -121 259 -148 51 -35 69 -55 97 -105 19 -34 21 -54 21 -197 0 -257 -22
-300 -154 -300 -127 0 -147 32 -155 255 l-6 150 -143 3 -142 3 -11 -28 c-7
-17 -11 -104 -12 -213 0 -200 13 -281 58 -370 40 -78 85 -116 170 -147 42 -15
82 -18 240 -18 164 0 197 3 240 19 86 33 127 68 170 141 59 101 92 343 81 600
-14 341 -43 389 -321 532 -162 83 -258 145 -287 186 -21 30 -23 45 -26 200 -3
153 -2 171 17 211 12 23 33 48 47 54 34 16 119 15 152 -2 62 -32 72 -63 81
-241 l6 -120 136 -3 c116 -2 138 0 150 14 18 22 25 288 10 396 -25 170 -80
243 -226 297 -51 19 -244 27 -352 15z"/>
<path d="M4610 10514 c-6 -14 -10 -39 -10 -55 0 -28 -21 -237 -70 -701 -5 -51
-14 -138 -19 -193 -6 -55 -22 -212 -36 -350 -15 -137 -31 -292 -36 -342 -5
-51 -13 -135 -19 -185 -12 -119 -40 -409 -40 -426 0 -10 39 -12 178 -10 l177
3 13 155 c6 85 18 214 25 285 l12 130 114 3 c63 1 118 -1 123 -6 6 -6 14 -65
18 -133 22 -311 31 -415 35 -426 3 -10 50 -13 186 -13 l182 0 -6 93 c-4 50
-11 133 -17 182 -5 50 -14 140 -19 200 -6 61 -17 178 -25 260 -9 83 -22 224
-31 315 -8 91 -19 208 -25 260 -5 52 -14 147 -20 210 -6 63 -15 156 -20 205
-9 81 -27 268 -46 475 -3 41 -11 78 -18 82 -6 4 -143 8 -304 8 l-292 0 -10
-26z m322 -429 c3 -39 12 -122 18 -185 31 -321 60 -691 54 -701 -3 -5 -51 -9
-106 -9 l-101 0 6 68 c4 37 11 112 16 167 5 55 17 179 25 275 18 206 34 358
42 418 5 32 10 42 23 40 12 -2 18 -20 23 -73z"/>
<path d="M5607 10534 c-4 -4 -7 -520 -7 -1146 l0 -1138 400 0 400 0 0 195 0
195 -217 2 -218 3 -5 945 -5 945 -171 3 c-93 1 -173 -1 -177 -4z"/>
<path d="M6552 10532 c-10 -7 -12 -242 -10 -1143 l3 -1134 330 -3 c267 -2 344
1 405 13 99 21 153 48 194 98 86 106 106 207 106 527 0 344 -17 417 -109 486
-28 21 -51 44 -51 51 0 6 16 24 36 39 72 55 94 133 101 365 16 490 -69 661
-346 698 -95 12 -639 15 -659 3z m590 -372 c42 -33 53 -95 52 -300 -1 -275
-17 -302 -176 -309 -57 -2 -94 1 -101 8 -8 8 -12 105 -12 309 -1 163 0 300 2
305 2 4 51 7 107 7 87 0 107 -3 128 -20z m11 -911 c41 -32 58 -104 64 -273 6
-152 -6 -264 -32 -316 -22 -43 -50 -51 -170 -48 l-110 3 -1 315 c0 173 2 321
4 328 3 8 35 12 112 12 93 0 111 -3 133 -21z"/>
<path d="M7914 10532 c-6 -4 -16 -59 -23 -122 -24 -241 -32 -317 -41 -395 -10
-86 -23 -206 -40 -375 -6 -58 -20 -195 -31 -305 -11 -110 -24 -240 -29 -290
-5 -49 -14 -135 -20 -190 -9 -90 -24 -233 -49 -500 -5 -49 -6 -93 -2 -98 4 -4
84 -6 177 -5 l169 3 7 55 c4 30 15 154 23 274 9 120 19 225 22 232 4 11 33 14
124 14 l118 0 5 -32 c3 -18 7 -73 10 -123 12 -193 26 -371 32 -397 l6 -28 177
0 c98 0 180 2 182 4 5 5 -3 116 -20 281 -5 55 -21 219 -35 365 -44 459 -46
483 -56 580 -5 52 -13 142 -19 200 -9 100 -49 517 -71 738 -5 57 -12 108 -15
113 -7 11 -585 12 -601 1z m310 -444 c4 -40 11 -120 16 -178 11 -121 23 -251
41 -435 7 -71 12 -164 13 -205 l1 -75 -94 -3 c-52 -1 -98 1 -104 7 -6 6 -5 47
2 113 6 56 15 153 21 213 5 61 17 178 25 260 8 83 17 179 20 215 12 138 17
160 35 160 14 0 18 -13 24 -72z"/>
<path d="M8897 10533 c-4 -3 -7 -519 -7 -1145 l0 -1138 180 0 180 0 2 463 3
462 68 3 c63 3 70 1 96 -26 16 -16 34 -47 40 -70 13 -46 37 -235 46 -362 14
-199 43 -427 56 -452 9 -16 28 -18 194 -18 160 0 185 2 185 15 0 9 -6 48 -14
88 -14 71 -22 147 -51 442 -18 179 -31 271 -52 345 -18 65 -60 147 -79 154
-24 9 -15 41 16 60 109 64 142 189 143 541 1 278 -26 417 -99 518 -43 59 -129
101 -237 116 -84 12 -659 15 -670 4z m578 -369 c49 -32 60 -89 60 -314 -1
-227 -9 -276 -55 -322 -29 -28 -31 -29 -127 -26 l-98 3 -3 325 c-1 179 0 330
3 337 7 20 189 18 220 -3z"/>
<path d="M10095 10528 c-3 -7 -4 -521 -3 -1143 l3 -1130 183 -3 182 -2 0 532
c-1 492 0 533 16 533 12 0 21 -19 36 -75 10 -41 35 -127 54 -190 20 -63 45
-146 56 -185 11 -38 24 -79 28 -90 4 -11 12 -36 18 -55 28 -93 87 -285 114
-372 l31 -98 197 0 c108 0 199 3 202 6 4 3 -2 31 -12 62 -10 31 -42 131 -70
222 -29 91 -80 255 -115 365 -35 110 -80 254 -100 320 -20 66 -46 144 -56 173
-10 29 -19 67 -19 85 0 30 41 155 135 412 20 55 56 156 80 225 23 69 66 188
94 265 27 77 47 143 42 148 -4 4 -90 6 -191 5 l-184 -3 -162 -484 c-90 -266
-167 -488 -172 -493 -6 -6 -12 -6 -17 1 -3 6 -7 228 -8 494 l-2 482 -178 3
c-136 2 -179 0 -182 -10z"/>
<path d="M11546 10531 c-4 -5 -13 -74 -21 -153 -8 -78 -19 -188 -25 -243 -6
-55 -15 -145 -21 -200 -5 -55 -14 -143 -20 -195 -5 -52 -14 -135 -18 -185 -29
-292 -72 -712 -81 -785 -5 -47 -14 -130 -20 -185 -6 -55 -15 -140 -20 -190 -6
-49 -10 -102 -10 -117 l0 -28 174 0 c114 0 177 4 181 11 4 6 11 81 17 167 5
86 15 211 21 277 l12 120 120 0 120 0 7 -55 c4 -30 12 -129 19 -220 7 -91 15
-194 18 -230 l6 -65 184 -3 184 -2 -7 82 c-10 137 -37 419 -60 653 -9 83 -22
224 -31 315 -28 304 -33 351 -65 665 -5 50 -17 173 -26 275 -8 102 -19 210
-22 240 l-7 55 -302 3 c-177 1 -304 -2 -307 -7z m322 -503 c53 -576 72 -812
68 -824 -5 -12 -26 -14 -103 -12 l-98 3 1 50 c0 28 5 102 12 165 6 63 22 243
36 400 31 344 32 350 54 350 15 0 19 -18 30 -132z"/>
<path d="M13040 9395 l0 -1145 308 0 c330 0 425 9 522 51 86 37 147 131 178
274 31 140 34 192 39 695 5 509 -3 755 -27 895 -16 94 -61 203 -103 251 -89
101 -202 124 -629 124 l-288 0 0 -1145z m623 741 c14 -13 31 -41 37 -62 18
-58 31 -548 25 -894 -8 -425 -20 -496 -85 -524 -17 -7 -73 -12 -132 -12 l-103
1 -3 758 -2 757 118 0 c111 0 121 -2 145 -24z"/>
<path d="M14417 10533 c-4 -3 -7 -23 -7 -44 0 -21 -4 -71 -9 -111 -6 -40 -15
-118 -20 -173 -6 -55 -15 -145 -21 -200 -5 -55 -17 -170 -25 -255 -9 -85 -20
-193 -25 -240 -10 -94 -25 -241 -41 -395 -5 -55 -14 -140 -19 -190 -6 -49 -17
-160 -25 -245 -9 -85 -20 -191 -25 -235 -5 -44 -10 -105 -12 -135 l-2 -55 177
-3 c97 -1 177 -1 178 0 1 7 18 231 29 383 6 94 13 176 15 183 3 9 37 12 124
12 l120 0 5 -32 c5 -32 7 -59 26 -328 5 -69 12 -146 16 -172 l7 -48 178 0 c98
0 180 3 182 8 3 4 1 50 -4 102 -20 227 -30 324 -44 465 -8 83 -20 200 -25 260
-5 61 -14 155 -20 210 -5 55 -14 147 -20 205 -5 58 -14 148 -20 200 -5 52 -17
172 -26 265 -8 94 -20 215 -25 270 -5 55 -12 138 -16 185 -3 47 -10 93 -15
103 -8 16 -33 17 -307 17 -164 0 -301 -3 -304 -7z m317 -420 c3 -27 11 -102
16 -168 12 -150 26 -307 40 -450 23 -232 26 -290 16 -297 -16 -11 -194 -10
-201 1 -3 6 -1 50 4 98 5 48 15 142 21 208 6 66 15 161 20 210 6 50 17 167 26
260 9 94 18 173 21 178 2 4 10 7 18 7 8 0 15 -17 19 -47z"/>
<path d="M15214 10527 c-2 -7 -3 -91 -2 -187 l3 -175 155 -3 c108 -2 157 -6
162 -15 4 -6 8 -435 8 -952 l-1 -940 176 -3 175 -2 0 939 c0 517 3 946 6 955
5 14 29 16 160 16 l154 0 -2 188 -3 187 -493 3 c-396 2 -494 0 -498 -11z"/>
<path d="M16416 10528 c-3 -7 -7 -47 -10 -88 -4 -41 -11 -115 -16 -165 -13
-109 -24 -220 -44 -425 -8 -85 -20 -200 -26 -255 -14 -133 -29 -270 -40 -380
-16 -156 -51 -496 -71 -690 -18 -169 -25 -266 -20 -271 2 -2 82 -4 177 -4
l173 0 5 33 c3 17 7 68 10 112 12 175 26 352 31 393 l6 42 119 0 c141 0 124
25 140 -205 5 -88 15 -208 21 -268 l12 -107 181 2 181 3 -3 60 c-1 33 -7 103
-12 155 -6 52 -21 217 -35 365 -14 149 -30 311 -35 360 -5 50 -14 140 -20 200
-35 369 -61 646 -71 755 -6 69 -15 152 -20 186 -5 33 -9 84 -9 113 0 97 22 91
-321 91 -231 0 -301 -3 -303 -12z m318 -400 c3 -18 10 -96 16 -173 12 -148 22
-266 40 -455 25 -255 28 -295 21 -303 -4 -4 -52 -6 -107 -5 l-99 3 2 35 c2 42
28 321 52 570 5 52 15 151 21 219 6 69 13 128 16 133 12 19 33 6 38 -24z"/>
<path d="M18997 10533 c-4 -3 -7 -425 -7 -937 0 -1023 1 -1038 59 -1163 75
-160 182 -200 519 -190 l173 5 72 36 c99 50 141 111 185 266 13 47 16 192 19
1023 l4 967 -178 -2 -178 -3 -5 -915 c-5 -839 -6 -918 -22 -946 -22 -40 -33
-44 -127 -44 -88 0 -114 13 -140 69 -14 32 -16 126 -19 939 l-3 902 -173 0
c-95 0 -176 -3 -179 -7z"/>
<path d="M20211 10526 c-8 -9 -10 -318 -9 -1142 l3 -1129 310 -3 c324 -4 444
5 520 38 107 46 172 150 196 315 5 38 10 174 10 300 -1 339 -19 414 -122 482
-21 14 -39 30 -39 35 0 6 18 28 41 50 85 82 105 186 96 498 -6 220 -14 271
-57 366 -53 117 -135 172 -289 193 -126 17 -645 15 -660 -3z m591 -366 c43
-33 53 -95 52 -300 -2 -280 -17 -305 -182 -305 l-97 0 -5 308 c-3 170 -3 311
-1 313 2 2 50 4 106 4 87 0 106 -3 127 -20z m18 -918 c42 -43 55 -117 55 -322
0 -190 -10 -246 -50 -286 -23 -23 -31 -24 -140 -24 l-115 0 0 330 0 330 111 0
c108 0 111 -1 139 -28z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
